<template>
    <div>
      <v-dialog
        content-class="create-general-memo-modal"
        v-model="createIncomingLetter"
        persistent
        scrollable
        max-width="960"
        origin="center center"
        overlay-opacity=".3"
      >
        <v-card
          id="createIncomingLetter"
          class="height-95vh"
          v-if="memoRecipient && memoRecipient.length > 0"
        >
          <v-card-title class="pa-0">
            <v-toolbar flat>
              <v-toolbar-title>
                {{ $t("card.create-from-external-letter") }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip
                bottom
                v-if="
                  !$vuetify.breakpoint.smAndDown && userIsKrani
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind:data-hint="$t('introjs.this-button-for-submit-action')"
                    data-hintposition="middle-middle"
                    data-position="bottom-right-aligned"
                    v-bind:loading="axiosProcessing"
                    v-bind:disabled="axiosProcessing"
                    color="blue-grey"
                    class="ma-2 white--text"
                    @click="menuAction('submit')"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon dark class="mr-2">
                      {{ iconSubmit }}
                    </v-icon>
                    {{ $t("button.submit") }}
                  </v-btn>
                </template>
                <span>{{ $t("tooltip.submit-to-upline") }}</span>
              </v-tooltip>
  
              <v-menu
                v-if="!$vuetify.breakpoint.smAndDown && !userIsKrani"
                bottom
                left
              >
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn
                        v-bind:data-hint="
                          $t('introjs.this-button-for-submit-action')
                        "
                        v-bind:disabled="axiosProcessing"
                        v-bind:loading="axiosProcessing"
                        data-hintposition="middle-middle"
                        data-position="bottom-right-aligned"
                        color="blue-grey"
                        dark
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...menu }"
                      >
                        <v-icon class="mr-2">{{ iconSubmit }}</v-icon>
                        {{ $t("button.submit") }}
                      </v-btn>
                    </template>
                    <span>{{ $t("tooltip.select-submit-to") }}</span>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, i) in menus"
                    v-bind:key="i"
                    @click="menuAction(item.command)"
                  >
                    <v-icon class="mr-3">{{ item.icon }}</v-icon>
                    <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
  
              <v-menu v-if="!$vuetify.breakpoint.smAndDown" bottom left>
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn
                        v-bind:data-hint="
                          $t('introjs.this-button-for-submit-action')
                        "
                        v-bind:disabled="axiosProcessing"
                        v-bind:loading="axiosProcessing"
                        class="ma-2"
                        data-hintposition="middle-middle"
                        data-position="bottom-right-aligned"
                        color="blue-grey"
                        dark
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...menu }"
                      >
                        <v-icon class="mr-2">{{ iconSave }}</v-icon>
                        {{ $t("button.save") }}
                      </v-btn>
                    </template>
                    <span>{{ $t("tooltip.select save mode") }}</span>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, i) in menuSave"
                    v-bind:key="i"
                    @click="menuAction(item.command)"
                  >
                    <v-icon class="mr-3">{{ item.icon }}</v-icon>
                    <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
  
              <v-menu
                v-if="$vuetify.breakpoint.smAndDown"
                bottom
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>{{ iconMenu }}</v-icon>
                  </v-btn>
                </template>
  
                <v-list>
                  <v-list-item
                    v-for="(item, i) in mobileMenus"
                    v-bind:key="i"
                    @click="menuAction(item.command)"
                  >
                    <v-icon class="mr-3">{{ item.icon }}</v-icon>
                    <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
  
              <v-tooltip bottom v-if="draftIsSaved">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind:data-hint="$t('introjs.this-button-for-save-as-draft')"
                    data-hintposition="middle-middle"
                    data-position="bottom-right-aligned"
                    v-bind:loading="axiosProcessing"
                    v-bind:disabled="axiosProcessing"
                    color="blue-grey"
                    class="ma-2 white--text"
                    @click="menuAction('print_preview')"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon dark class="mr-2">
                      {{ iconPrint }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("tooltip.print-preview") }}</span>
              </v-tooltip>
  
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon @click="showHelper" v-on="on" v-bind="attrs">
                    <v-icon>{{ iconHelp }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("tooltip.help-me") }}</span>
              </v-tooltip>
  
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="createIncomingLetter = false"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon>{{ iconClose }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("tooltip.close-not-save") }}</span>
              </v-tooltip>
            </v-toolbar>
          </v-card-title>
  
          <v-divider></v-divider>
  
          <v-card-text height="100%">
            <v-row class="mt-3">
              <div class="pl-0 pr-0 col-sm-12 col-md-10">
                <v-alert
                  v-if="$v.form.kepada.$error && !$v.form.kepada.required"
                  dense
                  type="error"
                  v-bind:icon="iconAlert"
                  class="modal-alert v-alert--narrow v-alert--bubble bottom--left mt-4 mb-0"
                >
                  {{ $t("error.select-at-least-one-memo-recipient") }}
                </v-alert>
                <v-autocomplete
                  v-bind:data-hint="$t('introjs.this-is-memo-recipient-selector')"
                  data-hintposition="middle-middle"
                  data-position="bottom-left-aligned"
                  hide-details="true"
                  v-model="form.kepada"
                  v-bind:disabled="isUpdating"
                  v-bind:items="memoRecipient"
                  v-bind:menu-props="{
                    closeOnClick: true,
                    closeOnContentClick: true,
                  }"
                  hide-selected
                  deletable-chips
                  small-chips
                  v-bind:search-input.sync="searchInput"
                  @change="searchInput = ''"
                  chips
                  color="blue-grey lighten-2"
                  v-bind:label="$t('label.select-memo-recipients')"
                  item-text="nama"
                  item-value="id"
                  multiple
                  clearable
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      v-bind:input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="removePenerima(data.item)"
                    >
                      <v-avatar left>
                        <v-img
                          v-if="
                            data.item.kelompok != 'GROUP' && data.item.avatar_path
                          "
                          v-bind:src="data.item.avatar_path"
                        ></v-img>
                        <v-icon
                          v-else-if="
                            data.item.kelompok != 'GROUP' &&
                            !data.item.avatar_path
                          "
                          class="mr-0"
                        >
                          {{ iconUser }}
                        </v-icon>
                        <v-icon v-else class="mr-0">
                          {{ iconGroup }}
                        </v-icon>
                      </v-avatar>
                      {{ data.item.nama }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content
                        v-text="data.item"
                      ></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-avatar>
                        <img
                          v-if="
                            data.item.kelompok != 'GROUP' && data.item.avatar_path
                          "
                          v-bind:src="data.item.avatar_path"
                        />
                        <v-icon
                          v-else-if="
                            data.item.kelompok != 'GROUP' &&
                            !data.item.avatar_path
                          "
                        >
                          {{ iconUser }}
                        </v-icon>
                        <v-icon v-else>
                          {{ iconGroup }}
                        </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.nama"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.kelompok"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
                <v-alert
                  v-if="recipientCcSimilar"
                  dense
                  type="error"
                  v-bind:icon="iconAlert"
                  class="modal-alert v-alert--narrow v-alert--bubble top--left mt-4 mb-0"
                >
                  {{ $t("error.recipient-and-cc-must-not-have-the-same-items") }}
                </v-alert>
              </div>
              <div class="pa-0 vertical-all-child col-sm-12 col-md-2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-on="on"
                      v-bind="attrs"
                      class="avoore-icon-checkbox checkbox--primary checkbox--share"
                    >
                      <input id="chk-cc" type="checkbox" v-model="hasCC" />
                      <label for="chk-cc"></label>
                    </div>
                  </template>
                  <span>{{ $t("tooltip.add-cc") }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-on="on"
                      v-bind="attrs"
                      class="avoore-icon-checkbox checkbox--primary checkbox--hourglass ml-2"
                    >
                      <input
                        id="chk-urgent"
                        type="checkbox"
                        v-model="form.urgensi"
                      />
                      <label for="chk-urgent"></label>
                    </div>
                  </template>
                  <span>{{ $t("tooltip.this-memo-is-urgent") }}</span>
                </v-tooltip>
              </div>
            </v-row>
  
            <v-row class="mt-3" v-if="hasCC">
              <v-col cols="12" md="12" sm="12" class="pl-0 pr-0">
                <v-alert
                  v-if="$v.form.tembusan.$error && !$v.form.tembusan.required"
                  dense
                  type="error"
                  v-bind:icon="iconAlert"
                  class="modal-alert v-alert--narrow v-alert--bubble bottom--left mb-0 mt-4"
                >
                  {{ $t("error.select-at-least-one-memo-cc") }}
                </v-alert>
  
                <v-alert
                  v-if="recipientCcSimilar"
                  dense
                  type="error"
                  v-bind:icon="iconAlert"
                  class="modal-alert v-alert--narrow v-alert--bubble bottom--left mb-2"
                >
                  {{ $t("error.recipient-and-cc-must-not-have-the-same-items") }}
                </v-alert>
  
                <v-autocomplete
                  v-bind:data-hint="
                    $t('introjs.this-is-memo-cc-recipient-selector')
                  "
                  data-hintposition="middle-middle"
                  data-position="bottom-left-aligned"
                  hide-details="true"
                  v-model="form.tembusan"
                  v-bind:disabled="isUpdating"
                  v-bind:items="recipientList"
                  v-bind:menu-props="{
                    closeOnClick: true,
                    closeOnContentClick: true,
                  }"
                  hide-selected
                  deletable-chips
                  small-chips
                  v-bind:search-input.sync="searchInput"
                  v-on:change="searchInput = ''"
                  chips
                  color="blue-grey lighten-2"
                  v-bind:label="$t('label.select-memo-cc')"
                  item-text="nama"
                  item-value="id"
                  multiple
                  clearable
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      v-bind:input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="removeTembusan(data.item)"
                    >
                      <v-avatar left>
                        <v-img
                          v-if="
                            data.item.kelompok != 'GROUP' && data.item.avatar_path
                          "
                          :src="data.item.avatar_path"
                        ></v-img>
                        <v-icon
                          v-else-if="
                            data.item.kelompok != 'GROUP' &&
                            !data.item.avatar_path
                          "
                          class="mr-0"
                        >
                          {{ iconUser }}
                        </v-icon>
                        <v-icon v-else class="mr-0">
                          {{ iconGroup }}
                        </v-icon>
                      </v-avatar>
                      {{ data.item.nama }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content
                        v-text="data.item"
                      ></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-avatar>
                        <img
                          v-if="
                            data.item.kelompok != 'GROUP' && data.item.avatar_path
                          "
                          :src="data.item.avatar_path"
                        />
                        <v-icon
                          v-else-if="
                            data.item.kelompok != 'GROUP' &&
                            !data.item.avatar_path
                          "
                        >
                          {{ iconUser }}
                        </v-icon>
                        <v-icon v-else>
                          {{ iconGroup }}
                        </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.nama"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.kelompok"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="mt-4">
              <v-alert
                v-if="$v.form.lembaga.$error && !$v.form.lembaga.required"
                dense
                type="error"
                :icon="iconAlert"
                class="modal-alert v-alert--narrow v-alert--bubble bottom--left mt-4 mb-0 mt-4"
              >
                {{ $t("error.sender-institution-must-be-filled") }}
              </v-alert>
              <div class="col-sm-12 col-md-11 pa-0 vertical-all-child">
                <v-autocomplete
                  auto-select-first
                  clearable
                  v-model="form.lembaga"
                  v-bind:label="$t('label.select institution')"
                  v-bind:items="comptLembagas"
                  item-text="value"
                  item-value="value"
                ></v-autocomplete>
              </div>
              <div
                class="pl-0 pr-0 col-sm-12 col-md-1 d-flex justify-center content-center"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      @click="gotoInstitution"
                      v-on="on"
                      v-bind="attrs"
                      :disabled="!currentUser.institution_admin"
                    >
                      <v-icon>{{ iconList }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("tooltip.manage institution data") }}</span>
                </v-tooltip>
              </div>
            </v-row>
            <v-row>
              <v-col cols="12" class="pl-0 pr-0">
                <v-alert
                  v-if="$v.form.hal.$error && !$v.form.hal.required"
                  dense
                  type="error"
                  :icon="iconAlert"
                  class="modal-alert v-alert--narrow v-alert--bubble bottom--left mt-4 mb-0 mt-4"
                >
                  {{ $t("error.memo-subject-must-be-filled") }}
                </v-alert>
                <v-alert
                  v-if="$v.form.hal.$error && !$v.form.hal.minLength"
                  dense
                  type="error"
                  :icon="iconAlert"
                  class="modal-alert v-alert--narrow v-alert--bubble bottom--left mt-4 mb-0 mt-4"
                >
                  {{
                    $t("error.memo-subject-min-length") +
                    " 10 " +
                    $t("label.characters")
                  }}
                </v-alert>
                <v-alert
                  v-if="$v.form.hal.$error && !$v.form.hal.maxLength"
                  dense
                  type="error"
                  :icon="iconAlert"
                  class="modal-alert v-alert--narrow v-alert--bubble bottom--left mt-4 mb-0 mt-4"
                >
                  {{
                    $t("error.memo-subject-max-length") +
                    " 250 " +
                    $t("label.characters")
                  }}
                </v-alert>
                <v-text-field
                  :data-hint="$t('introjs.this-is-memo-subject')"
                  data-hintposition="middle-middle"
                  data-position="bottom-right-aligned"
                  v-model="form.hal"
                  hide-details="true"
                  maxlength="250"
                  :hint="$t('tooltip.min-max-char-for-memo-subject')"
                  :label="$t('label.memo-subject')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pl-0 pr-0">
                <v-alert
                  v-if="$v.form.isi.$error && !$v.form.isi.required"
                  $error
                  dense
                  type="error"
                  :icon="iconAlert"
                  class="modal-alert v-alert--narrow v-alert--bubble bottom--left mb-4"
                >
                  {{ $t("error.memo-message-must-be-filled") }}
                </v-alert>
                <v-alert
                  v-if="$v.form.isi.$error && !$v.form.isi.minLength"
                  dense
                  type="error"
                  :icon="iconAlert"
                  class="modal-alert v-alert--narrow v-alert--bubble bottom--left mt-4 mb-0 mt-4"
                >
                  {{
                    $t("error.memo-message-min-length") +
                    " 27 " +
                    $t("label.characters")
                  }}
                </v-alert>
                <v-alert
                  v-if="$v.form.isi.$error && !$v.form.isi.maxLength"
                  dense
                  type="error"
                  :icon="iconAlert"
                  class="modal-alert v-alert--narrow v-alert--bubble bottom--left mt-4 mb-0 mt-4"
                >
                  {{
                    $t("error.memo-message-max-length") +
                    " 300000 " +
                    $t("label.characters")
                  }}
                </v-alert>
              </v-col>
              <froala-editor
                v-bind:data-hint="$t('introjs.this-is-memo-message')"
                data-hintposition="middle-middle"
                data-position="bottom-right-aligned"
                v-bind:oldEditorData="form.isi"
                v-on:editorData="changeEditorData"
                v-on:froalaIsWorking="froalaIsWorking"
                v-bind:editorDisabled="editorDisabled"
              >
              </froala-editor>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" sm="12">
                <div
                  class="attachment-list mt-4"
                  v-if="form.attachments && form.attachments.length > 0"
                >
                  <p
                    class="mb-1 text--avore-grey"
                    v-html="$t('text.new-attachment-list')"
                  ></p>
  
                  <ol>
                    <li
                      v-for="item in form.attachments"
                      v-bind:key="item.id"
                      v-bind:value="item.id"
                      class="file--list"
                    >
                      <a href="javascript:;">
                        {{ item.name }} - {{ item.size | formatSize }}
                        <span v-if="item.error">{{ item.error }}</span>
                        <span v-else-if="item.success">success</span>
                        <span v-else-if="item.active">active</span>
                        <span v-else></span>
  
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              class="ml-2"
                              color="red"
                              v-on:click="deleteAttachment(item)"
                              v-on="on"
                              v-bind="attrs"
                            >
                              <v-icon>{{ iconDelete }}</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t("tooltip.delete-this-file") }}</span>
                        </v-tooltip>
                      </a>
                    </li>
                  </ol>
                </div>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <file-upload
                      input-id="create-general"
                      v-on="on"
                      v-bind="attrs"
                      class="mt-4 att-button v-btn v-btn--is-elevated v-btn--has-bg v-size--default blue-grey"
                      post-action="/upload/post"
                      extensions="jpg,jpeg,png,pdf,doc,docx,xls,xlsx,zip,rar,ppt,pptx"
                      accept="image/png, image/jpeg, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation
                       , application/vnd.rar, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword,
                       application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/zip"
                      v-bind:multiple="true"
                      v-bind:size="1024 * 1024 * 35"
                      v-bind:active="true"
                      v-bind:maximum="totalAttCount"
                      v-model="form.attachments"
                      v-on:input-filter="inputFilter"
                      v-on:input-file="inputFile"
                      ref="upload"
                      v-bind:title="$t('tooltip.add-attachments')"
                    >
                      <v-icon class="text--white">{{ iconAttachment }}</v-icon>
                    </file-upload>
                  </template>
                  <span>{{ $t("tooltip.add-attachments") }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-text>
          <v-overlay v-bind:absolute="true" v-bind:value="editorIsWorking">
          </v-overlay>
        </v-card>
  
        <v-card v-if="!memoRecipient" style="height: 100% !important">
          <v-skeleton-loader
            class="mx-auto"
            max-width="100%"
            width="100%"
            type="actions, article, list-item-three-line"
          >
          </v-skeleton-loader>
        </v-card>
      </v-dialog>
      <upline-selector
        v-bind:showUplineDialog="showUplineDialog"
        v-on:showHideUplineDialog="showHideUplineDialog"
        v-on:uplineSelected="uplineSelected"
      ></upline-selector>
  
      <peer-selector
        v-bind:showPeerDialog="showPeerDialog"
        v-on:showHidePeerDialog="showHidePeerDialog"
        v-on:peerSelected="peerSelected"
      ></peer-selector>
    </div>
  </template>
  <script>
  import { mapActions, mapState } from "vuex";
  import froalaEditor from "./FroalaEditor";
  import VueUploadComponent from "vue-upload-component";
  import uplineSelector from "./UplineSelector.vue";
  import peerSelector from "./PeerSelector.vue";
  import {
    mdiContentSave,
    mdiMenu,
    mdiSend,
    mdiClose,
    mdiBookArrowUp,
    mdiAccountGroup,
    mdiAccount,
    mdiAlertCircleOutline,
    mdiHelpCircleOutline,
    mdiAttachment,
    mdiDeleteForever,
    mdiPrinter,
    mdiViewList,
  } from "@mdi/js";
  
  import toolbarMenu from "@/constants/toolbarmenu";
  import { validationMixin } from "vuelidate";
  import introJS from "intro.js/intro.js";
  import "intro.js/minified/introjs.min.css";
  
  const createHelper = introJS();
  
  const {
    required,
    requiredIf,
    minLength,
    maxLength,
  } = require("vuelidate/lib/validators");
  
  export default {
    name: "create-general-memo-modal",
    components: {
      froalaEditor,
      "file-upload": VueUploadComponent,
      uplineSelector,
      peerSelector,
    },
    data() {
      // const srcs = {
      //   1: "https://image.flaticon.com/icons/png/128/709/709722.png"
      // };
      return {
        iconSave: mdiContentSave,
        iconSubmit: mdiSend,
        iconSubmitUpline: mdiBookArrowUp,
        iconPrint: mdiPrinter,
        iconMenu: mdiMenu,
        iconClose: mdiClose,
        iconGroup: mdiAccountGroup,
        iconUser: mdiAccount,
        iconAlert: mdiAlertCircleOutline,
        iconHelp: mdiHelpCircleOutline,
        iconAttachment: mdiAttachment,
        iconDelete: mdiDeleteForever,
        iconList: mdiViewList,
        menus: toolbarMenu.createDraftToolbarMenu,
        menuSave: toolbarMenu.createDraftToolbarSaveMenu,
        editorIsWorking: false,
        timeout: null,
        loader: null,
        axiosProcessing: false,
        autoUpdate: true,
        isUpdating: false,
        searchInput: "",
        hasCC: false,
        totalAttSize: 0,
        totalAttCount: 5,
        rules: [(v) => v.length <= 500 || this.$t("tooltip.max-500-char")],
        subjectTextRules: [
          (v) => !!v || this.$t("tooltip.this-field-is-required"),
          (v) =>
            (v && v.length >= 10) || this.$t("tooltip.this-field-min-10-char"),
          (v) =>
            (v && v.length <= 250) || this.$t("tooltip.this-field-max-250-char"),
        ],
        tipeKrani : ['KRANI','SEKRETARIS_KRANI','SEKRETARIS_DIRSEVP'],
        form: {
          id: undefined,
          _method: undefined,
          action: "save",
          is_masuk: 1,
          parent_memo_id: this.memoIdToReply,
          urgensi: undefined,
          attachments: undefined,
          kepada: this.memoKepadaToReply,
          lembaga: undefined,
          hal: undefined,
          isi: 'Mohon Bapak/Ibu berkenan untuk memberikan disposisi atas surat terlampir. Terima kasih.',
          reviser_nik: undefined,
          initiator_nik: undefined,
          tembusan: [],
          selectedUpline: undefined,
        },
        showUplineDialog: false,
        showPeerDialog: false,
        finalData: null,
        isSaved: false,
      };
    },
    mixins: [validationMixin],
    validations: {
      form: {
        kepada: { required },
        lembaga: { required },
        tembusan: {
          required: requiredIf(function () {
            return this.hasCC;
          }),
        },
        hal: { required, minLength: minLength(10), maxLength: maxLength(250) },
        isi: { required, minLength: minLength(27), maxLength: maxLength(50000) },
      },
    },
    computed: {
      ...mapState("resources", ["memoRecipient", "dirSevps", "lembagas"]),
      ...mapState("draft", [
        "draftMode",
        "filterParams",
        "memoToReply",
        "draftIsSaved",
      ]),
      ...mapState("user", ["currentUser"]),
      ...mapState(["helpGroupRecipientDialog", "tipToHide"]),
      userIsPjp() {
        if (!this.currentUser) return false;
        return ["PJP", "PJP_KANPUS"].includes(this.currentUser.level_aplikasi_elemen);
      },
      mobileMenus() {
        if (
          this.tipeKrani.includes(this.currentUser.level_aplikasi_elemen)
        ) {
          return toolbarMenu.createDraftToolbarMenuMobileKrani;
        } else {
          return toolbarMenu.createDraftToolbarMenuMobile;
        }
      },
      comptLembagas() {
        if (!this.lembagas || this.lembagas.length < 1) return [];
        return this.lembagas;
      },
      memoKepadaToReply() {
        if (!this.memoToReply) return null;
        return this.memoToReply.kepada;
      },
      memoIdToReply() {
        if (!this.memoToReply) return null;
        return this.memoToReply.id;
      },
      memoNumberSubjectToReply() {
        if (!this.memoToReply) return null;
        return this.memoToReply.nomor + ", Hal : " + this.memoToReply.hal;
      },
      userIsKrani() {
        if (!this.currentUser) return false;
        return this.tipeKrani.includes(
          this.currentUser.level_aplikasi_elemen
        );
      },
      userHQ() {
        if (!this.currentUser) return false;
        let unitHasBagian = ["00", "10"];
        return unitHasBagian.includes(this.currentUser.kode_unit);
      },
      userSekper() {
        if (!this.currentUser) return false;
        return (
          this.currentUser.kode_unit === "00" &&
          this.currentUser.kode_bagian == "BAG237"
        );
      },
      createIncomingLetter: {
        get() {
          return this.$store.state.createIncomingLetter;
        },
        set(val) {
          this.$store.commit("SET_CREATE_INCOMING_LETTER", val);
        },
      },
      recipientList() {
        if (!this.memoRecipient) return [];
        return this.memoRecipient;
      },
      recipientCcSimilar() {
        if (!this.form.kepada || !this.form.tembusan) return false;
        return this.similarItemExist(this.form.kepada, this.form.tembusan);
      },
      editorDisabled() {
        if (!this.form.kepada || !this.form.hal) return true;
        if (this.form.kepada.length > 0 && this.form.hal.length > 9) return false;
        return true;
      },
    },
    watch: {
      createIncomingLetter(val) {
        //empty oldData everytime modal is showing;
        if (!val) {
          this.setMemoToReply(undefined);
          return createHelper.hideHints();
        }
        this.formReset();
        this.resetRecipientPeerUpline();
        this.getDataResource({
          source: "memo-recipients",
          tipe: "masuk",
          bgProcess: true,
        });
        this.getDataResource({
          source: "peer-revisers",
          bgProcess: true,
        });
        this.getDataResource({
          source: "atasan",
          bgProcess: true,
        });
        this.getDataResource({
          source: "lembagas",
          bgProcess: true,
        });
      },
      memoNumberSubjectToReply(val) {
        if (!val) return;
        setTimeout(() => {
          this.form.hal = "Membalas Memo Nomor : " + val;
          this.form.kepada = this.memoKepadaToReply;
          this.form.parent_memo_id = this.memoIdToReply;
        }, 300);
      },
      loader() {
        const l = this.loader;
        this[l] = !this[l];
  
        setTimeout(() => {
          this[l] = false;
          this.loader = null;
          this.createIncomingLetter = false;
        }, 3000);
      },
      tipToHide(val) {
        if (!val) return;
        createHelper.hideHint(val);
      },
    },
    methods: {
      ...mapActions("resources", ["resetRecipientPeerUpline", "getDataResource"]),
      ...mapActions(["setMessageBox"]),
      ...mapActions("draft", [
        "submitMyDraft",
        "setMemoToReply",
        "setDraftIsSaved",
      ]),
      changeEditorData(e) {
        this.form.isi = e;
      },
      showHideUplineDialog(val) {
        this.showUplineDialog = val;
      },
      showHidePeerDialog(val) {
        this.showPeerDialog = val;
      },
      removePenerima(item) {
        const index = this.form.kepada.indexOf(item.id);
        if (index >= 0) this.form.kepada.splice(index, 1);
      },
      removeTembusan(item) {
        const index = this.form.tembusan.indexOf(item.id);
        if (index >= 0) this.form.tembusan.splice(index, 1);
      },
      gotoInstitution() {
        if (!this.currentUser.institution_admin) return;
        this.$router.push("/setting/institutions");
        setTimeout(() => {
          this.createIncomingLetter = false;
        }, 500);
      },
      dirSevpsFilter(item, queryText) {
        const textOne = item.nama.toLowerCase();
        const textTwo = item.kode.toLowerCase();
        const searchText = queryText.toLowerCase();
  
        return (
          textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
        );
      },
      formReset() {
        this.setDraftIsSaved(false);
        this.$v.$reset();
        this.axiosProcessing = false;
        this.hasCC = false;
        this.form = {
          id: undefined,
          _method: undefined,
          action: "save",
          is_masuk: 1,
          //parent_memo_id: undefined,
          direksi: [],
          urgensi: undefined,
          attachments: undefined,
          kepada: undefined,
          hal: undefined,
          isi: 'Mohon Bapak/Ibu berkenan untuk memberikan disposisi atas surat terlampir. Terima kasih.',
          reviser_nik: undefined,
          initiator_nik: undefined,
          tembusan: [],
          selectedUpline: undefined,
        };
        if (this.form.attachments && this.form.attachments.length > 0) {
          this.$refs.upload.clear();
        }
      },
      menuAction(command) {
        if (!command) return;
        this.$v.$touch();
        if (this.$v.$invalid) return;
        const actionSave = ["save_and_close", "save_only"];
        if (actionSave.includes(command)) {
          this.form.action = "save";
        } else {
          this.form.action = command;
        }
        switch (command) {
          case "save_only":
            this.saveThis();
            break;
          case "save_and_close":
            this.saveThis("close");
            break;
          case "submit":
          case "direct_send":
            this.submitThis();
            break;
          case "share_to_revision":
            this.shareThis();
            break;
          case "print_preview":
            this.printPreview();
            break;
          case "help":
            this.showHelper();
            break;
          default:
            break;
        }
      },
      saveThis(mode) {
        this.selectedUpline = null;
        this.finalData = this.clearFormData(this.form);
        this.axiosProcessing = true;
  
        this.submitMyDraft({ action: "save", formData: this.finalData });
        this.$nextTick(() => {
          this.axiosProcessing = false;
          this.isSaved = true;
          if (mode === "autosave") {
            this.$dialog.notify.info(
              this.$t("msgbox.this-draft-has-autosaved-by-image-upload"),
              {
                position: "top-left",
                timeout: 5000,
              }
            );
          } else if (mode === "close") {
            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
              this.$store.commit("SET_CREATE_INCOMING_LETTER", false);
            }, 500);
          } else {
            return;
          }
        });
      },
      submitThis() {
        if (
          this.tipeKrani.includes(this.currentUser.level_aplikasi_elemen) &&
          !this.form.selectedUpline
        ) {
          return (this.showUplineDialog = true);
          //open upline selector if krani
        }
  
        this.finalData = this.clearFormData(this.form);
  
        if (this.tipeKrani.includes(this.currentUser.level_aplikasi_elemen) && this.form.selectedUpline) {
           this.finalData.append("initiator_nik", this.form.selectedUpline);
           this.finalData.delete("selectedUpline");
        }
        
        this.axiosProcessing = true;
        this.submitMyDraft({ action: "submit", formData: this.finalData });
        this.$nextTick(() => {
          this.axiosProcessing = false;
          if (this.timeout) clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            this.$store.commit("SET_CREATE_INCOMING_LETTER", false);
          }, 500);
        });
      },
      shareThis() {
        if (
          ["KARPIM", "KARPIM_KANPUS", "KASUBAG", "KADIS"].includes(
            this.currentUser.level_aplikasi_elemen
          ) &&
          !this.form.reviser_nik
        ) {
          return (this.showPeerDialog = true);
          //open upline selector if krani
        }
        this.finalData = this.clearFormData(this.form);
  
        this.axiosProcessing = true;
  
        this.submitMyDraft({
          action: "share_to_revision",
          formData: this.finalData,
        });
        this.$nextTick(() => {
          this.axiosProcessing = false;
          if (this.timeout) clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            this.$store.commit("SET_CREATE_GENERAL_MEMO", false);
          }, 500);
        });
      },
  
      inputFilter(newFile, oldFile, prevent) {
        if (newFile && !oldFile) {
          if (
            parseInt(newFile.file.size) >= 35000000 ||
            parseInt(newFile.file.size) + parseInt(this.totalAttSize) >= 35000000
          ) {
            this.$dialog.notify.error(
              this.$t("error.attachment-size-max-35-mb"),
              {
                position: "top-left",
                timeout: 3000,
              }
            );
            return prevent();
          }
          if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
            return prevent();
          }
          if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
            return prevent();
          }
        }
      },
      async printPreview() {
        console.info("on progress");
      },
      inputFile(newFile, oldFile) {
        if (newFile && !oldFile) {
          // add
          this.totalAttSize += parseInt(newFile.size);
          if (!this.form.attachments) return;
          if (this.form.attachments.length === 1) {
            return this.$dialog.notify.success(
              this.$t("msgbox.attachment-will-upload-on-submit-or-save"),
              {
                position: "top-left",
                timeout: 3000,
              }
            );
          }
        }
        if (newFile && oldFile) {
          // update
          return;
        }
        if (!newFile && oldFile) {
          // remove
          //send to backend to remove by file_id
          this.totalAttSize -= parseInt(oldFile.size);
          this.deleteAttachment(oldFile);
        }
      },
  
      deleteAttachment(file) {
        if (!file) return;
        this.$refs.upload.remove(file);
      },
      showHelper() {
        setTimeout(() => {
          createHelper.setOptions({
            hintButtonLabel: this.$t("button.i-understand"),
          });
          createHelper.addHints();
          // createHelper.refresh();
          createHelper.showHints();
        }, 500);
      },
      uplineSelected(val) {
        if (!val) return;
        this.form.selectedUpline = val.nik;
        this.menuAction("submit");
      },
      peerSelected(val) {
        if (!val) return;
        this.form.reviser_nik = val.map(({ nik }) => nik).join();
        this.menuAction("share_to_revision");
      },
      froalaIsWorking(val) {
        this.editorIsWorking = val;
      },
    },
    filters: {
      formatSize(size) {
        if (!size) return;
        if (size > 1024 * 1024 * 1024 * 1024) {
          return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + " TB";
        } else if (size > 1024 * 1024 * 1024) {
          return (size / 1024 / 1024 / 1024).toFixed(2) + " GB";
        } else if (size > 1024 * 1024) {
          return (size / 1024 / 1024).toFixed(2) + " MB";
        } else if (size > 1024) {
          return (size / 1024).toFixed(2) + " KB";
        }
        return size.toString() + " B";
      },
    },
  };
  </script>
  <style lang="scss">
  .v-dialog.v-dialog--active.v-dialog--persistent.create-general-memo-modal {
    height: 98vh !important;
    max-height: 100vh !important;
  
    .fr-element.fr-view {
      height: calc(100vh - 340px) !important;
      max-height: 100vh !important;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .v-dialog.v-dialog--active.v-dialog--persistent.create-general-memo-modal {
      margin: 10px !important;
  
      .fr-element.fr-view {
        height: calc(100vh - 440px) !important;
        max-height: 100vh !important;
      }
    }
  }
  
  .width-max {
    margin: 10px !important;
  }
  
  .height-95vh {
    min-height: 98vh;
  }
  </style>
  